<template>
  <section>
    <v-container>
      <div class="friendly-4 my-4 mb-10">
        <span>About Sea2Sea Scuba</span>
      </div>
      <div class="mb-16">
        <v-row>
          <v-col md="4" class="hidden-sm-and-down">
            <v-row>
              <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
                <v-img :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`" :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`" aspect-ratio="1" class="grey lighten-2 rounded-lg">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-card flat>
              <v-card-text class="friendly-1" style="height: 100%;">
                Sea2Sea Scuba is a 5-star PADI Scuba center with knowledgeable instructors guiding divers of all levels, renting well-maintained gear, selling top-rated brands, and offering personalized courses and dive trips. Safety is a top priority, and they also provide equipment servicing and repairs. The shop's exceptional customer service, high-quality equipment, and safety commitment make them a top choice for Phoenix divers.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <HorizontalLineDivider class="primary mb-0 mt-6"></HorizontalLineDivider>
      <quick-info-action title="Come visit us!" description="" routeToName="retail" :poster="'https://s3.us-east-1.wasabisys.com/sea2sea/media/StoreFront.png'"></quick-info-action>
      <HorizontalLineDivider class="primary mb-0 pt-0"></HorizontalLineDivider>
      <div class="my-6 hidden-xs-only">
        <v-row>
          <v-col offset-lg="1" offset-md="0">
            <div class="friendly-3">Our Goals</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="7" offset-lg="1" md="8" offset-md="0">
            <v-card color="primary" flat style="height: 100%;">
              <v-card-title class="white--text friendly-1">
                Safety
              </v-card-title>
              <v-card-text class="white--text hidden-xs-only">
                At Sea2Sea Scuba, safety is not just a top priority, it's a fundamental value that we uphold in every aspect of our business. Our experienced instructors undergo rigorous training to ensure that they can provide the highest quality dive training while prioritizing safety above all else. We also use state-of-the-art equipment and adhere to the strictest safety standards to guarantee a safe and enjoyable diving experience for everyone. Whether you're a seasoned diver or a beginner, you can trust Sea2Sea Scuba for peace of mind and an unforgettable underwater adventure. So come dive with us and experience the thrill of exploring the underwater world in a safe and controlled environment.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="3" md="4">
            <v-card color="grey lighten-1" flat style="height: 100%;">
              <v-card-title class="black--text friendly-1">
                Sustainability
              </v-card-title>
              <v-card-text class="black--text hidden-xs-only">
                Sea2Sea Scuba is committed to lake conservation and sustainability. Our team organizes cleanups, promotes eco-friendly diving practices, and advocates for responsible use of our natural resources.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" offset-lg="1" md="4" offset-md="0">
            <v-card color="grey lighten-1" flat style="height: 100%;">
              <v-card-title class="black--text friendly-1">
                Satisfaction
              </v-card-title>
              <v-card-text class="black--text hidden-xs-only">
                Our friendly and knowledgeable staff are dedicated to providing exceptional customer service and ensuring that our customers have everything they need for a successful dive. At Sea2Sea Scuba, we strive to make scuba diving a memorable and enjoyable experience for everyone.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="7" md="8">
            <v-card color="primary" flat style="height: 100%;">
              <v-card-title class="white--text friendly-1">
                Dive Training, Equipment, and Services
              </v-card-title>
              <v-card-text class="white--text hidden-xs-only">
                Sea2Sea Scuba is committed to building a strong and inclusive community of scuba diving enthusiasts in Phoenix, Arizona. Our dive training courses, led by certified instructors, are designed to cater to divers of all levels of experience. We offer a wide range of high-quality dive equipment for sale or rent, as well as equipment servicing and repairs. Our friendly and knowledgeable staff are always on hand to offer advice and support, and our community of passionate divers is dedicated to sharing their knowledge and experience with others.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="hidden-sm-and-up">
        <div class="friendly-3 mt-6">Our Goals</div>
        <v-expansion-panels class="mt-4">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-2">
                      Safety
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-1">
                      At Sea2Sea Scuba, safety is not just a top priority, it's a fundamental value that we uphold in every aspect of our business. Our experienced instructors undergo rigorous training to ensure that they can provide the highest quality dive training while prioritizing safety above all else. We also use state-of-the-art equipment and adhere to the strictest safety standards to guarantee a safe and enjoyable diving experience for everyone. Whether you're a seasoned diver or a beginner, you can trust Sea2Sea Scuba for peace of mind and an unforgettable underwater adventure. So come dive with us and experience the thrill of exploring the underwater world in a safe and controlled environment.
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-2">
                      Sustainability
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-1">
                      Sea2Sea Scuba is committed to lake conservation and sustainability. Our team organizes cleanups, promotes eco-friendly diving practices, and advocates for responsible use of our natural resources.
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-2">
                       Satisfaction
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-1">
                      Our friendly and knowledgeable staff are dedicated to providing exceptional customer service and ensuring that our customers have everything they need for a successful dive. At Sea2Sea Scuba, we strive to make scuba diving a memorable and enjoyable experience for everyone.
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-2">
                       Dive Training, Equipment, and Services
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <span class="friendly-1">
                      Sea2Sea Scuba is committed to building a strong and inclusive community of scuba diving enthusiasts in Phoenix, Arizona. Our dive training courses, led by certified instructors, are designed to cater to divers of all levels of experience. We offer a wide range of high-quality dive equipment for sale or rent, as well as equipment servicing and repairs. Our friendly and knowledgeable staff are always on hand to offer advice and support, and our community of passionate divers is dedicated to sharing their knowledge and experience with others.
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <HorizontalLineDivider class="primary mb-0 pt-0 mt-16"></HorizontalLineDivider>
      <div class="my-6">
        <v-row>
          <v-col offset-lg="1" offset-md="0">
            <div class="friendly-3 mt-5">The Community</div>
            // INSTAGRAM SCROLL BAR (SEA2SEA SCUBA) <br>
            // TESTIMONIALS <br>
            // COMMUNITY PARTNERS / ACTIVITES <br>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import HorizontalLineDivider from '@/components/HorizontalLineDivider.vue'
import QuickInfoAction from '@/components/QuickInfoAction.vue'

export default {
  name: 'Contact',
  components: { QuickInfoAction, HorizontalLineDivider },
  data: () => ({
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    }
  }),
  created () {
    this.doSetDocumentTitle('About Us', true)
  }
}
</script>

<style scoped lang="scss">
.bottomLineDivider {
  margin-top: -20px;
}
</style>
